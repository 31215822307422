import { AppBar, Box, Divider, FormControl, FormControlLabel, IconButton, InputLabel, Menu, MenuItem, Select, SelectChangeEvent, Switch, Tab, Tabs, Theme, Toolbar, Tooltip, Typography } from "@mui/material";

import MenuIcon from '@mui/icons-material/Menu';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';

import WebsiteTranslations from "../translation/WebsiteTranslations";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";

import { darkMode, setDarkMode } from "../redux/reducer/reducer-darkmode";
import { category, setCategory } from "../redux/reducer/reducer-category";
import { language } from "../redux/reducer/reducer-language";
import { Categorys, Languages } from "./Constants";
import { coloring, enableColoring } from "../redux/reducer/reducer-coloring";

import DefaultLogo from "./../logo/logo.svg";

import "./../css/Gradient.css"

interface HeaderProps {

  name: string,
  pages: string[],
  lightMode: string,
  darkMode: string,
  colorMode: string,
  standardMode: string,
  language: string,
  setLanguage: (lang : string) => void,
};


const Header = (props : HeaderProps) => {

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [, setState] = useState({});

    const translation = WebsiteTranslations;
    const lang = useAppSelector(language);

    useEffect(() => {
        // set language, setState needs to be called to refresh the page
        translation.setLanguage(lang);
        setState({});
      }, [lang, translation]);

    const mode = useAppSelector(darkMode);

    const cat = useAppSelector(category);
    const dispatch = useAppDispatch();

    const switchCategory = (category : number) =>
    {
      switch(category)
      {
        case Categorys.start       :
        case Categorys.news        :
        case Categorys.about_us    :
        case Categorys.members     : 
        case Categorys.projects    : 
        case Categorys.imprint     : break; 
        default:
          console.warn("invalid category selected: " + category);
          category = Categorys.start;
          break;
      }
      dispatch(setCategory(category));
    };
  
    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
      setAnchorEl(null);
    };
    
    const handleSelection = (id : number) => {    
      setAnchorEl(null);
      switchCategory(id);
    };

    // component change functions
    const ToggleDarkMode = () => {
      dispatch(setDarkMode(!mode));
    };

    const SetLanguage = (event : SelectChangeEvent<string>) => {
      props.setLanguage(event.target.value);
    };

    const colorMode = useAppSelector(coloring);
    const dark = useAppSelector(darkMode);

    const toggleColoring = () => {
      dispatch(enableColoring(!colorMode));
    };

    return ( <Box sx={{flex: 1}}>
        <AppBar position="static" color="transparent" sx={{ padding : (theme:Theme) => theme.spacing(1), }}>
          <Toolbar disableGutters >

              <Box sx={{ flexGrow: 0, width: "100px", display: 'flex', padding : (theme) => theme.spacing(1), overflow: 'hidden', justifyContent: 'center'}}>
                < object style={{flexGrow: 1}} id={dark ? "image-small" : "image-light-small"} type="image/svg+xml" data={DefaultLogo} aria-label="geko-ev-logo"/>
              </Box>

              <Typography
                  variant="h6"
                  noWrap
                  component="div"
                  ml={1}
                  sx={{ flexGrow: 0, fontSize:"1.15rem" }}
                >
                {props.name}
              </Typography>

              {/* Visible on Small Screens */} 
              <Box sx={{ flexGrow: 1,  display: { xs: 'flex', md: 'none' }, justifyContent:"end" }}>
                <IconButton
                  size="large"
                  aria-label="appbar menu"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenuClick}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>

                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                  sx={{
                    display: { xs: 'block', md: 'none' },
                  }}
                >
                  {props.pages.map((page, idx) => (
                    <MenuItem key={"translation-item-"+idx+"-"+page} onClick={() => handleSelection(idx)}>
                      <Typography textAlign="center">{page}</Typography>
                    </MenuItem>
                  ))}

                    <Divider/>

                    <MenuItem key={"coloring-item"} onClick={toggleColoring} divider>
                      <Typography textAlign="center">{!colorMode ? props.standardMode : props.colorMode}</Typography>
                    </MenuItem>

                    <MenuItem key={"dark-mode-item"} >
                      <Box sx={{display: "flex"}}>
                        <IconButton sx={{ flex: 0, marginRight: (theme:Theme) => theme.spacing(1) }}
                          color="inherit"
                          onClick={ToggleDarkMode}
                        >
                          {mode ? <LightModeIcon/> : <DarkModeIcon/>}
                        </IconButton>
                        <Divider flexItem orientation="vertical"/>

                        <FormControl sx={{flex: 1}}>
                          <InputLabel id="language-selector-label">{props.language}</InputLabel>
                          <Select
                            labelId="language-selector-label"
                            id="language-selector"
                            value={lang}
                            label={props.language}
                            onChange={SetLanguage}
                          >
                            {Languages.map( (language, idx) =>
                              <MenuItem key={"language-item-"+idx+"-"+language} value={language}>
                                  {language.toUpperCase()}
                              </MenuItem>)}
                          </Select>
                        </FormControl>
                      </Box>

                    </MenuItem>
                </Menu>                
              </Box>

              {/* Visible on Big Screens */} 
              <Box sx={{ flex: 1, display: { xs: 'none', md: 'flex' }}}>
   
                {/* Categorys */} 
                <Box sx={{ flex: 1, justifyContent: "center" }}>
                  <Tabs
                    value={cat}
                    centered
                    onChange={(ev, val) => handleSelection(val)}>
                  {props.pages.map((page, idx) => (
                  <Tab
                      value={idx}
                      label={page}
                      key={"category-item-"+idx+"-"+page}
                      sx={{ borderRadius: '40px' }}
                    />
                    ))}    
                  </Tabs>        
                </Box>

                {/* Language and Dark / Light Toggle*/}
                <Box sx={{ flexGrow: 0, display: 'flex', alignItems: "center"}}>
                  <FormControl component="fieldset" variant="standard">
                    <FormControlLabel
                      control={
                        <Switch checked={colorMode} onChange={toggleColoring} name="switch-colors" />
                      }
                      label={!colorMode ? props.standardMode : props.colorMode}
                    />
                  </FormControl>

                  <Tooltip title={mode ? props.lightMode : props.darkMode}>
                    <IconButton sx={{ flex: 0, marginRight: (theme:Theme) => theme.spacing(1) }}
                      color="inherit"
                      onClick={ToggleDarkMode}
                    >
                      {mode ? <LightModeIcon/> : <DarkModeIcon/>}
                    </IconButton>
                  </Tooltip>
                
                  <FormControl sx={{flex: 1}}>
                    <InputLabel id="language-selector-label">{props.language}</InputLabel>
                    <Select
                      labelId="language-selector-label"
                      id="language-selector"
                      value={lang}
                      label={props.language}
                      onChange={SetLanguage}
                    >
                      {Languages.map( (language, idx) =>
                        <MenuItem key={"language-item-"+idx+"-"+language} value={language}>
                            {language.toUpperCase()}
                        </MenuItem>)}
                    </Select>
                  </FormControl>
                </Box>

              </Box>

          </Toolbar>
        </AppBar>
      </Box> );
};

export default Header;