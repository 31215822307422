import { Box, Container, Link, Paper, SxProps, Theme, Typography } from "@mui/material";
import WebsiteTranslations from "../../translation/WebsiteTranslations";

const LinkList = () => {

    const translation = WebsiteTranslations;
    const link_list = translation.link_list;
    const gutterBottom = {paddingBottom: (theme: Theme) => theme.spacing(2) } as SxProps<Theme>;

    const sx = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: (theme: Theme) => theme.spacing(4),
        marginBottom: (theme: Theme) => theme.spacing(2)
    } as SxProps<Theme>;

    return (<Paper sx={{flex: 1, alignItems: "center", padding: (theme: Theme) => theme.spacing(4), overflow: "auto", background: "rgba(0, 0, 0, 0)"}}>
                <Container maxWidth = "lg" >
                    
                    <Paper sx={sx}>
                        <Typography variant="h5" gutterBottom>{link_list.title}</Typography>
                        <Typography sx={{textAlign: "center"}} variant="body1" gutterBottom>{link_list.text}</Typography>
                    </Paper>

                    <Paper sx={sx}>
                        <Link href="https://www.facebook.com/profile.php?id=100087837693225" underline="hover">
                            <Typography variant="h5" gutterBottom>{link_list.facebook}</Typography>
                        </Link>
                    </Paper>

                    <Paper sx={sx}>
                        <Link href="https://www.instagram.com/garden_eden_kollektiv/" underline="hover">
                            <Typography variant="h5" gutterBottom>{link_list.instgram}</Typography>
                        </Link>                        
                    </Paper>

                    <Paper  sx={sx}>

                    <Box sx={{...gutterBottom, display: 'flex', flexDirection:'column', alignItems: "center"}}>
                            <Typography variant="h6" gutterBottom sx={{padding: (theme: Theme) => theme.spacing(0) }}>
                                {translation.protection_tower.title} 
                            </Typography>
                            <Typography variant="body2" gutterBottom sx={{padding: (theme: Theme) => theme.spacing(0), paddingBottom: (theme: Theme) => theme.spacing(0) }}>
                                {translation.protection_tower.info_1}
                            </Typography>
                            <Typography variant="body2" gutterBottom sx={{padding: (theme: Theme) => theme.spacing(0) }}>
                                {translation.protection_tower.info_2}
                            </Typography>
                            <Link href="mailto:info@geko-ev.org">info@geko-ev.org</Link>
                        </Box>
                    
                        <Box sx={{...gutterBottom, display: 'flex', alignItems: "center"}}>
                            <Link  href="/docs/projects/Artenschutzturm_Koenigsee.pdf" target="_blank" rel="noreferrer">
                                <Typography variant="body2" sx={{padding: (theme: Theme) => theme.spacing(1), paddingBottom: (theme: Theme) => theme.spacing(0)  }}>
                                    {translation.protection_tower.download}
                                </Typography>
                            </Link>
                        </Box>
                    </Paper>

                    {/*<Paper  sx={sx}>
                    
                        <Box sx={{...gutterBottom, display: 'flex', flexDirection:'column', alignItems: "center"}}>
                            <Typography variant="h6" gutterBottom sx={{padding: (theme: Theme) => theme.spacing(0) }}>
                                {translation.jufoe.info_title} {translation.jufoe.name}
                            </Typography>
                            <Typography variant="body2" sx={{padding: (theme: Theme) => theme.spacing(0) }}>
                                {translation.jufoe.info_1}
                            </Typography>
                            <Typography variant="body2" gutterBottom sx={{padding: (theme: Theme) => theme.spacing(0), paddingBottom: (theme: Theme) => theme.spacing(1) }}>
                                {translation.jufoe.info_2}
                            </Typography>
                            <Typography variant="body1" gutterBottom sx={{padding: (theme: Theme) => theme.spacing(0) }}>
                                {translation.jufoe.thanks}
                            </Typography>
                        </Box>

                        <Box sx={{...gutterBottom, display: 'flex', alignItems: "center"}}>
                            <Link  href="/docs/jufoe/Einverstaendniserklaerung_Transport.pdf" target="_blank" rel="noreferrer">
                                <Typography variant="body2" sx={{padding: (theme: Theme) => theme.spacing(0) }}>
                                    {translation.jufoe.shuttle_u18}
                                </Typography>
                            </Link>
                        </Box>

                        <Box sx={{...gutterBottom, display: 'flex', alignItems: "center"}}>
                            <Link  href="/docs/jufoe/Einverstaendniserklaerung_Transport_Erwachsene.pdf" target="_blank" rel="noreferrer">
                                <Typography variant="body2" sx={{padding: (theme: Theme) => theme.spacing(0) }}>
                                    {translation.jufoe.shuttle_adult}
                                </Typography>
                            </Link>
                        </Box>
                    </Paper>

                    <Paper  sx={sx}>

                        <Box sx={{...gutterBottom, display: 'flex', flexDirection:'column', alignItems: "center"}}>
                            <Typography variant="h6" gutterBottom sx={{padding: (theme: Theme) => theme.spacing(0) }}>
                                {translation.documents.parenting.head}
                            </Typography>
                            <Typography variant="body2" gutterBottom sx={{padding: (theme: Theme) => theme.spacing(0) }}>
                                {translation.documents.parenting.info_1}
                            </Typography>
                            <Typography variant="body1" gutterBottom sx={{padding: (theme: Theme) => theme.spacing(0) }}>
                            {translation.documents.parenting.thanks}
                            </Typography>
                        </Box>
                        <Box sx={{...gutterBottom, display: 'flex', alignItems: "center"}}>
                            <Link  href="/docs/Muttizettel.pdf" target="_blank" rel="noreferrer">
                                <Typography variant="body2" sx={{padding: (theme: Theme) => theme.spacing(0) }}>
                                    {translation.documents.parenting.link}
                                </Typography>
                            </Link>
                        </Box>

                    </Paper>*/}
                </Container>
            </Paper>);
};

export default LinkList;