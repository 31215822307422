
import DefaultLogo from "./../../logo/logo_default.svg";
import "./../../css/Gradient.css"
import { useAppSelector } from "../../redux/hooks";
import { darkMode } from "../../redux/reducer/reducer-darkmode";
import { Box } from "@mui/material";

const StartPageView = () => {

  const dark = useAppSelector(darkMode);
  return (
    <Box sx={{flex: 1, display: 'flex', padding : (theme) => theme.spacing(5), paddingTop : (theme) => theme.spacing(8), paddingBottom : (theme) => theme.spacing(8), overflow: 'hidden', justifyContent: 'center'}}>
      {/*<Box component="img" src="./image/plakat.jpg" alt="Bolzplatzbeatz 15.06.2024" sx={{height: "100%",}}/>*/}
    < object style={{width: "100%"}} id={dark ? "image" : "image-light"} type="image/svg+xml" data={DefaultLogo} aria-label="geko-ev-logo" />
  </Box>
  );
  };

  export default StartPageView;